import { defineStore } from "pinia";
import Axios, { AxiosError } from "axios"

export interface User {
    id: string;
    name: string;
    email: string;
    avatarUrl: string | null;
    isAdmin: boolean;
    linkedAccounts: number;
}

export interface UserState {
    user: User | null;
}

export const useUserStore = defineStore("user", {
    state: (): UserState => ({
        user: null,
    }),

    actions: {
        async fetchUser(): Promise<void> {
            try {
                const userResult = await Axios.get<User | null>("/api/v0/auth/me");
                this.user = userResult.data;
            }
            catch(e) {
                if ((e as AxiosError).response?.status == 404)
                    this.user = null;
            }
        },

        logout(): void {
            this.user = null;
        }
    }
});
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

export {}

import 'vue-router'

declare module 'vue-router' {

    interface RouteMeta { // If the meta is undefined, the route will show as if it was defined and requiresAuth is true
        requiresAuth?: boolean; // If this is undefined in the route meta, it's implied to be true.
        disabledAfterLogin?: boolean; // If this is undefined in the route meta, it's implied to be false.
        hideOnNav?: boolean; // If undefined, it's implied to be false.
    }
}

export const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: () => import("@v/HomeView.vue"),
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/login",
        name: "login",
        component: () => import("@v/LoginView.vue"),
        meta: {
            requiresAuth: false,
            disabledAfterLogin: true,
        },
    },
    {
        path: "/requests",
        name: "requests",
        component: () => import("@/views/RequestView.vue"),
        meta: {},
    },
    {
        path: "/signup",
        name: "signup",
        component: () => import("@v/SignupView.vue"),
        meta: {
            requiresAuth: false,
            disabledAfterLogin: true,
        },
    },
    {
        path: "/signup/result",
        name: "result",
        component: () => import("@v/SignupResultView.vue"),
        meta: {
            requiresAuth: false,
            disabledAfterLogin: true,
            hideOnNav: true,
        },
    },
    {
        path: "/accounts",
        name: "accounts",
        component: () => import("@v/AccountView.vue"),
    },
    {
        path: "/mapping",
        name: "mapping",
        component: () => import("@v/MappingView.vue"),
        meta: {
            hideOnNav: true,
        },
    },
    {
        path: "/onboarding",
        name: "onboarding",
        component: () => import("@v/OnboardingView.vue"),
        meta: {
            hideOnNav: true,
            requiresAuth: true,
        },
    },
    {
        path: "/invites",
        name: "invites",
        component: () => import("@v/InviteView.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;

import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import { createPinia } from "pinia";
import "./styles/main.scss";
import { axiosSymbol, routerSymbol } from "./symbols";

const pinia = createPinia();

createApp(App)
    .use(router)
    .use(VueAxios, axios)
    .use(pinia)
    .provide(axiosSymbol, axios)
    .provide(routerSymbol, router)
    .mount("#app");


import { defineStore } from "pinia";
import { Ref } from "vue";

export interface ModalState {
    modals: {
        [key: string]: Modal
    }
}

export interface Modal {
    show: (message: string | ModalConfig) => Promise<any>
}

export interface ModalConfig {
    message: string;
    title: string | null;
}

export const useModalStore = defineStore("modals", {
    state: (): ModalState => ({
        modals: {},
    }),
    actions: {
        register(type: string, ref: Ref<Modal>) {
            this.modals[type] = ref.value;
        },

        async show(type: string, message: string | ModalConfig): Promise<any> {
            const modal = this.modals[type];
            if (modal == undefined) return;
            return await modal.show(message);
        },
    }
});